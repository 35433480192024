import { CSSpzPage } from '@shared/contentstack';
import { default_breakpoints } from '@src/styles/breakpoints';
import React from 'react';
import CMSComponentWrapper from '../component-wrapper';
import { color_lightest_grey } from '@src/styles/colors';
import {
  filterDefinedComponents,
  mappedComponent,
} from '@src/shared/utilities/page.util';

export const SpzPage = ({ pageContext }: CSSpzPage) => {
  const {
    components,
  } = pageContext || {};
  return (
    <>
      {filterDefinedComponents(components).map((comp: any, index: number) =>
        mappedComponent(comp.type, comp, index)
      )}
    </>
  );
};
